import * as React from "react";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import PageDetails from "../../../components/PageDetails";
import groupImg1 from "../../../images/Group 1.png";
import groupImg2 from "../../../images/Group 2.png";
let isLocal = ["dev", "development"].includes(process.env.NODE_ENV);
let fr_url = (isLocal ? "" : process.env.FR_URL) + "/fr/ressources/";
let en_url = (isLocal ? "" : process.env.EN_URL) + "/en/resources/";

const isFrench = process.env.LANG === "FR";

const ResourcesFR = () => {
  if (!isFrench) {
    return (
      <meta
        http-equiv="refresh"
        content={`0;url=${process.env.FR_URL}/fr/ressources/`}
        id="redirectTag"
      />
    );
  }
  return (
    <>
      <Header
        title={
          "Ressources | Connecteur d'emplois gouvernementale des affaires mondiales Canada"
        }
        description={
          "Ressources | Connecteur d'emplois gouvernementale des affaires mondiales Canada"
        }
        lang={"FR"}
        langLink={en_url}
        breadcrumbs={[
          {
            text: "Ressources pour les opportunités d’emploi dans nos bureaux du Canada à l’étranger",
            url: fr_url,
          },
        ]}
        bodyClasses={"page-en-resources"}
      />
      <main
        property="mainContentOfPage"
        resource="#wb-main"
        class="container pb-5"
        typeof="WebPageElement"
      >
        <h1>
          Ressources pour les opportunités d’emploi dans nos bureaux du Canada à
          l’étranger
        </h1>
        <p />
        <a
          href={
            "https://www.international.gc.ca/global-affairs-affaires-mondiales/home-accueil.aspx?lang=fra"
          }
        >
          Affaires mondiales Canada (page d’accueil)
        </a>
        <p />
        <a
          href={
            "https://www.international.gc.ca/world-monde/country-pays/index.aspx?lang=fra"
          }
        >
          Où le Canada travaille à l'étranger
        </a>
        <p />
        <a
          href={
            "https://www.international.gc.ca/global-affairs-affaires-mondiales/corporate-ministere/contact-contactez/index.aspx?lang=fra"
          }
        >
          Contactez Affaires mondiales Canada
        </a>
        <p />
        <a
          href={
            "https://www.international.gc.ca/global-affairs-affaires-mondiales/corporate-ministere/contact-contactez/social-media-medias-sociaux.aspx?lang=fra"
          }
        >
          Restez informés à travers les médias sociaux et plateformes digitales{" "}
        </a>
        <p />
        <div className="row wb-eqht wb-init">
          <div className="col-sm-6">
            <div
              className="brdr-rds-0 eqht-trgt"
              style={{ verticalAlign: "top", minHeight: 200, marginTop: 20 }}
            >
              <img
                className="img-responsive full-width"
                src={groupImg1}
                alt=""
              />
            </div>
            <p />
          </div>
          <div className="col-sm-6">
            <div
              className="brdr-rds-0 eqht-trgt"
              style={{ verticalAlign: "top", minHeight: 200, marginTop: 20 }}
            >
              <img
                className="img-responsive full-width"
                src={groupImg2}
                alt=""
              />
            </div>
            <p />
          </div>
        </div>
        <PageDetails lang={"FR"} dateModified={"2022-01-08"} />
      </main>
      <Footer lang={"FR"} />
    </>
  );
};
export default ResourcesFR;
